<template>
  <BaseCard>
    <ConfirmDialog></ConfirmDialog>
    <DialogPreregisterVisitor
      v-model:dialog-visibility="isPreregisterDialogVisible"
      v-model:passed-erp-next-visitor.lazy="selectedVisitor"
      @pre-registered-successfully="showPreRegisterSuccess"
      @pre-register-failed="showPreRegisterError"
    ></DialogPreregisterVisitor>
    <div class="visitor-container">
      <div class="visitor-header">
        <div v-if="visitorTableDatas.length <= 0" id="no-visitors">Bisher keine Besucher</div>
        <div v-else id="visitor" class="infoHeader">Besucher im Büro</div>
        <Button
          v-tooltip.left="{ value: 'Besucher vorregistrieren', showDelay: 500 }"
          aria-label="Besucher vorregistrieren"
          icon="pi pi-user-plus"
          @click="isPreregisterDialogVisible = !isPreregisterDialogVisible"
        ></Button>
      </div>
      <DataTable
        v-if="visitorTableDatas.length > 0"
        :loading="isLoading"
        sort-field="erpNextVisitorLog.date"
        :sort-order="-1"
        :value="visitorTableDatas"
        class="visitor-table"
        scrollable
        selection-mode="single"
        @row-select="onRowSelectOpenPreregisterDialog"
      >
        <Column field="erpNextVisitorLog.date" header="Datum" sortable>
          <template #body="slotProps">
            <span>
              {{ convertDateToGerDateString(convertDateStringToDate(slotProps.data.erpNextVisitorLog.date)) }}
              {{ convertDateToGerTimeString(convertDateStringToDate(slotProps.data.erpNextVisitorLog.date)) }}
            </span>
          </template>
        </Column>
        <Column field="erpNextVisitorLog.visitor_name" header="Name" sortable>
          <template #body="slotProps">
            <div v-tooltip="{ value: slotProps.data.erpNextVisitorLog.visitor_name, showDelay: 500, autoHide: false }" class="multiline-text-column">
              {{ slotProps.data.erpNextVisitorLog.visitor_name }}
            </div>
          </template>
        </Column>
        <Column v-if="isBigScreen" field="erpNextVisitorLog.company_name" header="Firma" sortable>
          <template #body="slotProps">
            <div v-tooltip="{ value: slotProps.data.erpNextVisitorLog.company_name, showDelay: 500, autoHide: false }" class="multiline-text-column">
              {{ slotProps.data.erpNextVisitorLog.company_name }}
            </div>
          </template>
        </Column>
        <Column v-if="isBigScreen" field="erpNextVisitorLog.email" header="E-Mail Adresse">
          <template #body="slotProps">
            <div v-tooltip="{ value: slotProps.data.erpNextVisitorLog.email, showDelay: 500, autoHide: false }" class="multiline-text-column">
              {{ slotProps.data.erpNextVisitorLog.email }}
            </div>
          </template>
        </Column>
        <Column v-if="isBigScreen" field="erpNextVisitorLog.phone" header="Telefon">
          <template #body="{ data }">
            <div v-tooltip="{ value: data.erpNextVisitorLog.phone, showDelay: 500, autoHide: false }" class="multiline-text-column">
              {{ data.erpNextVisitorLog.phone }}
            </div>
          </template>
        </Column>
        <Column field="erpNextVisitorLog.reason" header="Grund">
          <template #body="slotProps">
            <div v-tooltip="{ value: slotProps.data.erpNextVisitorLog.reason, showDelay: 500, autoHide: false }" class="multiline-text-column">
              {{ slotProps.data.erpNextVisitorLog.reason }}
            </div>
          </template>
        </Column>
        <Column field="erpNextVisitorLog.left" header="An-/Abmeldung" style="max-width: 165px; width: 165px; overflow: hidden">
          <template #body="slotProps">
            <div style="display: block; text-align: center">
              <span v-if="slotProps.data.erpNextVisitorLog.left" v-tooltip.left="{ value: 'Zeitpunkt der Abmeldung', showDelay: 500 }">
                {{ convertDateToGerDateString(convertDateStringToDate(slotProps.data.erpNextVisitorLog.left)) }}
                {{ convertDateToGerTimeString(convertDateStringToDate(slotProps.data.erpNextVisitorLog.left)) }}
              </span>
              <InputGroup v-else-if="slotProps.data.erpNextVisitorLog.registration_code">
                <InputGroupAddon v-tooltip.left="{ value: 'Anmeldecode des Besuchenden zur Registrierung', showDelay: 500 }" class="code-display">
                  {{ slotProps.data.erpNextVisitorLog.registration_code }}
                </InputGroupAddon>
                <Button
                  v-tooltip.left="{ value: 'Anmeldecode in die Zwischenablage kopieren', showDelay: 500 }"
                  class="code-copy"
                  icon="pi pi-copy"
                  outlined
                  type="button"
                  @click="copyCodeToClipboard(slotProps.data.erpNextVisitorLog.registration_code)"
                />
              </InputGroup>
              <Button
                v-else
                v-tooltip.left="{ value: 'Besucher abmelden', showDelay: 500 }"
                aria-label="Besucher abmelden"
                label="Abmelden"
                icon="pi pi-sign-out"
                @click="initLogoutSequence(slotProps.data.erpNextVisitorLog)"
              />
            </div>
          </template>
        </Column>
        <Column v-if="isBigScreen" header="Aktionen">
          <template #body="slotProps">
            <div style="display: flex; flex-flow: row wrap; justify-content: center; align-items: center; gap: 8px; flex: 1">
              <Button
                v-tooltip.left="{ value: 'E-Mail senden', showDelay: 500 }"
                :disabled="
                  slotProps.data.erpNextVisitorLog.email_send || slotProps.data.erpNextVisitorLog.left || !slotProps.data.erpNextVisitorLog.registration_code
                "
                :loading="slotProps.data.isEmailSendLoading"
                icon="pi pi-envelope"
                aria-label="E-Mail senden"
                @click="onSendVisitorCodeAsMail(slotProps.data)"
              />
              <InputGroup v-if="slotProps.data.voucherCode">
                <InputGroupAddon v-tooltip.left="{ value: 'Wi-Fi Voucher des Besuchers', showDelay: 500 }" class="code-display">
                  {{ slotProps.data.voucherCode }}
                </InputGroupAddon>
                <Button
                  v-tooltip.left="{ value: 'Voucher in die Zwischenablage kopieren', showDelay: 500 }"
                  class="code-copy"
                  icon="pi pi-copy"
                  outlined
                  type="button"
                  @click="copyCodeToClipboard(slotProps.data.voucherCode)"
                />
              </InputGroup>
              <Button
                v-else
                v-tooltip.left="{ value: 'Wi-Fi Voucher erstellen', showDelay: 500 }"
                aria-label="Wi-Fi Voucher erstellen"
                icon="pi pi-wifi"
                :loading="slotProps.data.isVoucherCodeLoading"
                :disabled="slotProps.data.erpNextVisitorLog.left"
                data-testid="create-voucher-button"
                @click="onGenerateVoucher(slotProps.data)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
      <base-pop-up v-if="entryToConfirm" :pop-up-message="`Den Besucher ${entryToConfirm.visitor_name} abmelden?`">
        <template #content>
          <div class="base-pop-up-content">
            <span>Zeitpunkt der Abmeldung:</span>
            <TimePicker v-model="timeString" :autofocus="true" :can-clear="true" :disabled="false" class="time-input" />
          </div>
        </template>
        <Button outlined @click="entryToConfirm = undefined">Nein</Button>
        <Button data-testid="confirm-button" @click="confirmVisitorLogout">Ja</Button>
      </base-pop-up>
    </div>
  </BaseCard>
</template>

<script setup lang="ts">
import BaseCard from "@/ui/BaseCard.vue";
import { ErpNextVisitorLog, ErpNextVisitorRegistration, VoucherType, WifiVoucherCreationDto } from "@/data-types";
import { useToast } from "primevue/usetoast";
import BasePopUp from "@/ui/BasePopUp.vue";
import { onMounted, onUnmounted, ref, watch } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import { showErrorToast, showSuccessToast } from "@/utils/ToastService.ts";
import {
  convertDateStringToDate,
  convertDateToGerDateString,
  convertDateToGerTimeString,
  formatToLocalDate,
  isCurrentDateInRange,
  sendVisitorCodeAsMail,
} from "@/features/visitor/utils/VisitorUtil.ts";
import TimePicker from "@/ui/TimePicker.vue";
import DialogPreregisterVisitor from "@/features/visitor/components/DialogPreregisterVisitor.vue";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";

export interface VisitorTableData {
  erpNextVisitorLog: ErpNextVisitorLog;
  voucherCode: string;
  isVoucherCodeLoading: boolean;
  isEmailSendLoading: boolean;
}

const confirm = useConfirm();
const toast = useToast();
const visitorLog = ref<ErpNextVisitorLog[]>([]);
const visitorTableDatas = ref<VisitorTableData[]>([]);
const selectedVisitor = ref<ErpNextVisitorRegistration | null>(null);

const isLoading = ref(true);
const entryToConfirm = ref<ErpNextVisitorLog>();
const datetime24h = ref<Date>();
const isPreregisterDialogVisible = ref(false);
const timeString = ref<string>("");

const isBigScreen = ref(window.innerWidth >= 1400);

function updateScreenWidth() {
  isBigScreen.value = window.innerWidth >= 1400;
}

onMounted(() => {
  window.addEventListener("resize", updateScreenWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateScreenWidth);
});

watch(
  timeString,
  (newTimeString) => {
    if (!newTimeString) {
      datetime24h.value = undefined;
      return;
    }
    const [hours, minutes] = newTimeString.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes) || !entryToConfirm.value) return;
    const newTimestamp = new Date().setHours(hours, minutes);
    if (!isCurrentDateInRange(newTimestamp, entryToConfirm.value.date)) {
      datetime24h.value = new Date();
      timeString.value = convertDateToGerTimeString(datetime24h.value);
    } else datetime24h.value = new Date(newTimestamp);
  },
  { flush: "post" },
);

function onRowSelectOpenPreregisterDialog({ data: { erpNextVisitorLog } }: { data: VisitorTableData }): void {
  selectedVisitor.value = {
    company_name: erpNextVisitorLog.company_name,
    email: erpNextVisitorLog.email,
    date: new Date(),
    phone: erpNextVisitorLog.phone,
    reason: erpNextVisitorLog.reason,
    visitor_name: erpNextVisitorLog.visitor_name,
  };
  isPreregisterDialogVisible.value = true;
}

async function generateVoucher(visitorTableData: VisitorTableData) {
  visitorTableData.isVoucherCodeLoading = true;
  const reason = "Besucher: " + visitorTableData.erpNextVisitorLog.visitor_name + " Grund: " + visitorTableData.erpNextVisitorLog.reason;
  const wifiVoucherCreationDto: WifiVoucherCreationDto = {
    reason: reason,
    voucher_type: VoucherType.GUEST,
  };
  try {
    visitorTableData.voucherCode = await erpnextApi.createVoucherCode(wifiVoucherCreationDto);
  } catch (e: any) {
    showErrorToast(toast, e.message);
  } finally {
    visitorTableData.isVoucherCodeLoading = false;
  }
}

function onGenerateVoucher(visitorTableData: VisitorTableData) {
  confirm.require({
    message: `Mit 'JA' wird für ${visitorTableData.erpNextVisitorLog.visitor_name} ein Voucher erstellt, möchtest du das?`,
    header: "Bestätigung",
    icon: "pi pi-exclamation-triangle",
    rejectClass: "p-button-secondary p-button-outlined",
    rejectLabel: "Abbrechen",
    acceptLabel: "Ja",
    accept: () => {
      generateVoucher(visitorTableData);
    },
  });
}

function onSendVisitorCodeAsMail(visitorTableData: VisitorTableData) {
  confirm.require({
    message: `Mit 'JA' wird der Besuchercode an die E-Mail: ${visitorTableData.erpNextVisitorLog.email} gesendet, möchtest du das?`,
    header: "Bestätigung",
    icon: "pi pi-exclamation-triangle",
    rejectClass: "p-button-secondary p-button-outlined",
    rejectLabel: "Abbrechen",
    acceptLabel: "Ja",
    accept: () => {
      sendVisitorCodeAsMail(visitorTableData, toast);
    },
  });
}

function initLogoutSequence(_erpNextVisitorLog: ErpNextVisitorLog) {
  datetime24h.value = undefined;
  timeString.value = "";
  entryToConfirm.value = _erpNextVisitorLog;
}

function getVisitorLogs() {
  isLoading.value = true;
  erpnextApi
    .getVisitorLogs()
    .then((res: ErpNextVisitorLog[]) => {
      visitorLog.value = res;
      visitorTableDatas.value = res.map((log) => ({
        erpNextVisitorLog: log,
        voucherCode: "",
        isVoucherCodeLoading: false,
        isEmailSendLoading: false,
      }));
      isLoading.value = false;
    })
    .catch((err: Error) => showErrorToast(toast, err.message));
}

async function confirmVisitorLogout() {
  if (!entryToConfirm.value) {
    showErrorToast(toast, "Fehler: Keinen Besucher ausgewählt!");
    return;
  }
  isLoading.value = true;
  if (typeof entryToConfirm.value.date === "string") entryToConfirm.value.date = convertDateStringToDate(entryToConfirm.value.date);
  if (!isCurrentDateInRange(datetime24h.value, entryToConfirm.value.date)) datetime24h.value = undefined;
  await erpnextApi
    .logoutVisitor(entryToConfirm.value.name, formatToLocalDate(datetime24h.value))
    .then(() => {
      showSuccessToast(toast, "Besucher erfolgreich abgemeldet");
      getVisitorLogs();
    })
    .catch((err: Error) => showErrorToast(toast, err.message))
    .finally(() => {
      isLoading.value = false;
      entryToConfirm.value = undefined;
    });
}

function showPreRegisterSuccess(toastMsg: string) {
  showSuccessToast(toast, toastMsg);
  getVisitorLogs();
}

function showPreRegisterError(toastMsg: string) {
  showErrorToast(toast, toastMsg);
}

onMounted(() => {
  getVisitorLogs();
});

function copyCodeToClipboard(code: string) {
  navigator.clipboard.writeText(code);
  showSuccessToast(toast, `Code "${code}" in die Zwischenablage kopiert`);
}
</script>

<style scoped>
.multiline-text-column {
  max-width: 8.8rem;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.visitor-container {
  overflow: auto;
}

.visitor-header {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.infoHeader {
  font-size: 18px;
}

.code-display {
  flex: 1;
  color: var(--white);
  border-color: var(--borderColor);
}

.code-copy {
  border-color: var(--borderColor) !important;
}

.logout-button {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.base-pop-up-content {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 12px;
}

.generate-button {
  width: 110px;
  height: 39px;
}

.time-input {
  padding: 11px;
  border-radius: var(--border-radius);
  border: 1px solid;

  &:focus-visible {
    outline: unset;
  }
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Ensures content uses full button width */
}
</style>
