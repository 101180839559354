<template>
  <Dialog
    id="pre-register-visitor-dialog"
    v-model:visible="isDialogVisible"
    :content-props="dialogContentProps"
    :draggable="false"
    :pt="dialogPassThroughOptions"
    header="Besucher Anmelden"
    modal
    @show="resetErpNextVisitorRegistration"
    @hide="passedErpNextVisitor = null"
  >
    <form id="pre-register-visitor-form" class="dialog-content-container" @submit.prevent="preregisterVisitor" @reset.prevent="closeDialog">
      <div class="dialog-content">
        <div class="input-container">
          <label class="input-label" for="name">
            <span>Vor- und Nachname</span>
            <span v-tooltip="'Feld ist erforderlich!'" :class="{ 'p-error': v$.visitor_name.$error }">*</span>
          </label>
          <InputGroup class="dialog-input-group">
            <InputGroupAddon :style="{ 'border-color': getBorderColor(v$.visitor_name.$error) }" class="input-group-addon-icon">
              <i :class="{ 'p-error': v$.visitor_name.$error }" class="pi pi-user"></i>
            </InputGroupAddon>
            <InputText
              id="name"
              v-model.trim="erpNextVisitorRegistration.visitor_name"
              v-tooltip.top="visitorNameTooltip"
              :invalid="v$.visitor_name.$error"
              :readonly="isSaving"
              class="input-text"
              type="text"
              @blur="v$.visitor_name.$validate"
            />
          </InputGroup>
        </div>

        <div class="input-container">
          <label class="input-label" for="email">
            <span>E-Mail Adresse</span>
            <span v-tooltip="'Feld ist erforderlich!'" :class="{ 'p-error': v$.email.$error }">*</span>
          </label>
          <InputGroup>
            <InputGroupAddon :style="{ 'border-color': getBorderColor(v$.email.$error) }" class="input-group-addon-icon">
              <i :class="{ 'p-error': v$.email.$error }" class="pi pi-envelope"></i>
            </InputGroupAddon>
            <InputText
              id="email"
              v-model.trim="erpNextVisitorRegistration.email"
              v-tooltip.top="emailTooltip"
              :invalid="v$.email.$error"
              :readonly="isSaving"
              class="input-text"
              type="email"
              @blur="v$.email.$validate"
            />
          </InputGroup>
        </div>

        <div class="input-container">
          <label class="input-label" for="company"><span>Firma</span></label>
          <InputGroup>
            <InputGroupAddon class="input-group-addon-icon">
              <i class="pi pi-building"></i>
            </InputGroupAddon>
            <InputText
              id="company"
              v-model.trim="erpNextVisitorRegistration.company_name"
              v-tooltip.top="'Hier bitte den Firmennamen des Besuchers eintragen.'"
              class="input-text"
              :readonly="isSaving"
              type="text"
            />
          </InputGroup>
        </div>

        <div class="input-container">
          <label class="input-label" for="phone"><span>Telefonnummer</span></label>
          <InputGroup>
            <InputGroupAddon class="input-group-addon-icon">
              <i class="pi pi-phone"></i>
            </InputGroupAddon>
            <InputText
              id="phone"
              v-model.trim="erpNextVisitorRegistration.phone"
              v-tooltip.top="'Hier bitte die Telefonnummer des Besuchers eintragen.'"
              :readonly="isSaving"
              class="input-text"
              type="tel"
            />
          </InputGroup>
        </div>

        <div class="input-container">
          <label class="input-label" for="reason">
            <span>Grund</span>
            <span v-tooltip="'Feld ist erforderlich!'" :class="{ 'p-error': v$.reason.$error }">*</span>
          </label>
          <InputGroup>
            <InputGroupAddon :style="{ 'border-color': getBorderColor(v$.reason.$error) }" class="input-group-addon-icon">
              <i :class="{ 'p-error': v$.reason.$error }" class="pi pi-question-circle"></i>
            </InputGroupAddon>
            <InputText
              id="reason"
              v-model.trim="erpNextVisitorRegistration.reason"
              v-tooltip.top="reasonTooltip"
              :invalid="v$.reason.$error"
              :readonly="isSaving"
              class="input-text"
              type="text"
              @blur="v$.reason.$validate"
            />
          </InputGroup>
        </div>

        <div class="input-container">
          <label class="input-label" for="date">
            <span>Besucherzeit</span>
            <span v-tooltip="'Feld ist erforderlich!'" :class="{ 'p-error': v$.date.$error }">*</span>
          </label>
          <Calendar
            id="date"
            v-model="erpNextVisitorRegistration.date"
            v-tooltip.top="calendarTooltip"
            :invalid="v$.date.$error"
            :min-date="currentTime"
            :pt="calendarPassThroughOptions"
            :show-on-focus="false"
            :touch-u-i="useMobileView"
            :readonly="isSaving"
            class="input-text"
            show-button-bar
            show-icon
            show-time
            show-week
            @blur="v$.date.$validate"
            @hide="v$.date.$validate"
          />
        </div>
      </div>
    </form>

    <template #footer>
      <div class="dialog-button-container">
        <Button form="pre-register-visitor-form" label="Abbrechen" :disabled="isSaving" type="reset" text />
        <Button :loading="isSaving" :disabled="isSaving" datatest-id="anmelden-button" form="pre-register-visitor-form" label="Anmelden" type="submit" />
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, reactive, ref } from "vue";
import Dialog, { DialogPassThroughOptions, DialogProps } from "primevue/dialog";
import InputText from "primevue/inputtext";
import Calendar, { CalendarPassThroughOptions } from "primevue/calendar";
import Button from "primevue/button";
import { ErpNextVisitorRegistration } from "@/data-types";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { email, helpers, minLength, required } from "@vuelidate/validators";
import { currentDate, formatToLocalDate, setEmptyStringsInObjectToUndefined } from "@/features/visitor/utils/VisitorUtil.ts";
import { TooltipOptions } from "primevue/tooltip";
import { PassThrough } from "primevue/ts-helpers";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import erpnextApi from "@/rest/ErpnextApi.ts";

interface Emits {
  preRegisteredSuccessfully: [toastMsg: string];
  preRegisterFailed: [toastMsg: string];
}

const isDialogVisible = defineModel<boolean>("dialogVisibility", { required: true });
const passedErpNextVisitor = defineModel<ErpNextVisitorRegistration | null>("passedErpNextVisitor", { required: false });
const emit = defineEmits<Emits>();

const windowWidth = ref(window.innerWidth);
const isSaving = ref(false);
const currentTime = ref<Date>(currentDate());
const erpNextVisitorRegistration = reactive<ErpNextVisitorRegistration>(erpNextVisitorRegistrationInitial());
const useMobileView = computed(() => windowWidth.value < 1024);

const validations: ValidationArgs<ErpNextVisitorRegistration> = {
  reason: {
    required: helpers.withMessage("Grund ist erforderlich!", required),
    minLength: helpers.withMessage("Eingabe ist zu kurz!", minLength(3)),
  },
  visitor_name: {
    required: helpers.withMessage("Vor- und Nachname ist erforderlich!", required),
    minLength: helpers.withMessage("Eingabe ist zu kurz!", minLength(3)),
  },
  email: {
    required: helpers.withMessage("E-Mail Adresse ist erforderlich!", required),
    email: helpers.withMessage("Ungültige E-Mail Adresse.", email),
  },
  date: { required: helpers.withMessage("Datum ist erforderlich!", required) },
};
const v$ = useVuelidate<ErpNextVisitorRegistration>(validations, erpNextVisitorRegistration, { $rewardEarly: true });

const visitorNameTooltip = computed(() => generateTooltipErrorMessages(v$.value.visitor_name, "Hier bitte den Vor- und Nachnamen des Besuchers eintragen."));
const reasonTooltip = computed(() => generateTooltipErrorMessages(v$.value.reason, "Hier bitte den Grund des Besuchs eintragen."));
const emailTooltip = computed(() => generateTooltipErrorMessages(v$.value.email, "Hier bitte die E-mail Adresse des Besuchers eintragen."));
const calendarTooltip = computed(() => generateTooltipErrorMessages(v$.value.date, "Hier bitte den Zeitpunkt des Besuches auswählen."));

onMounted(() => window.addEventListener("resize", onWidthChange));
onUnmounted(() => window.removeEventListener("resize", onWidthChange));

function onWidthChange() {
  windowWidth.value = window.innerWidth;
}

function resetErpNextVisitorRegistration(): void {
  currentTime.value = currentDate();
  Object.assign(erpNextVisitorRegistration, passedErpNextVisitor.value ?? erpNextVisitorRegistrationInitial());
  v$.value.$reset();
}

async function preregisterVisitor(): Promise<void> {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  const date = formatToLocalDate(erpNextVisitorRegistration.date);
  if (!date) return;
  const requestObject: ErpNextVisitorRegistration = {
    ...setEmptyStringsInObjectToUndefined(erpNextVisitorRegistration),
    date,
  };

  try {
    isSaving.value = true;
    await erpnextApi.preregisterVisitor(requestObject);
    closeDialog();
    emit("preRegisteredSuccessfully", "Benutzer wurde erfolgreich angemeldet.");
  } catch (err: any) {
    emit("preRegisterFailed", err.message);
  } finally {
    isSaving.value = false;
  }
}

function closeDialog(): void {
  isDialogVisible.value = false;
}

// styling of the input fields
function getBorderColor(useErrorBorderStyle: boolean) {
  return useErrorBorderStyle ? "#ef9a9a" : "var(--borderColor)";
}

// styling of the tooltip and message of tooltip
function generateTooltipErrorMessages(
  validator: Validation<ValidationArgs<unknown>, ErpNextVisitorRegistration>,
  defaultTooltipMessage: string,
): TooltipOptions | string {
  const error = validator.$error;
  if (!error) return defaultTooltipMessage;
  const errors = validator.$silentErrors;
  return {
    pt: {
      arrow: { class: ["p-message", "p-message-error"], style: { borderRadius: 0 } },
      text: { class: ["p-message", "p-message-error"], style: { margin: 0, borderRadius: 0 } },
    },
    value: errors.map((error) => error.$message).join("\n"),
  };
}

// styling of the calendar and dropdown button
const calendarPassThroughOptions = computed(() => {
  return {
    input: { style: { borderBottomRightRadius: "unset", borderTopRightRadius: "unset" } },
    dropdownButton: {
      root: {
        class: { "p-button-outlined": true, "p-button-danger": v$.value.date.$error },
        style: {
          borderBottomLeftRadius: "unset",
          borderTopLeftRadius: "unset",
          borderColor: getBorderColor(v$.value.date.$error),
          borderLeft: "unset",
        },
      },
    },
  } as PassThrough<CalendarPassThroughOptions>;
});

// styling of the dialog
const dialogContentProps: DialogProps = { style: { paddingBottom: "6px" } };
const dialogPassThroughOptions: PassThrough<DialogPassThroughOptions> = {
  footer: {
    style: {
      padding: "24px",
      display: "flex",
      flexFlow: "row nowrap",
    },
  },
};
</script>

<script lang="ts">
function erpNextVisitorRegistrationInitial(): ErpNextVisitorRegistration {
  return {
    visitor_name: "",
    date: currentDate(),
    email: "",
    company_name: undefined,
    phone: undefined,
    reason: undefined,
  };
}
</script>

<style scoped>
* {
  --dialog-content-gap-x: 24px;
  --dialog-content-gap-y: 12px;
  --input-container-amount-inputfield: 2;
}

.dialog-content-container {
  flex: 1;
  max-width: 35rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
}

.dialog-content {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  gap: var(--dialog-content-gap-y) var(--dialog-content-gap-x);
}

.input-container {
  display: flex;
  flex-flow: column nowrap;
  gap: 6px;
  flex: 1 0 calc(100% / var(--input-container-amount-inputfield) - var(--dialog-content-gap-x));
}

.input-label {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: space-between;
  font-weight: bold;
  hyphens: auto;
}

.input-group-addon-icon {
  color: var(--grey);
  border-color: var(--borderColor);
}

.input-text {
  min-width: 10rem;
  border-color: var(--borderColor);
}

.dialog-button-container {
  display: flex;
  flex-flow: row wrap;
  gap: calc(var(--dialog-content-gap-x) / 2);

  & > * {
    flex: 1 0 calc(100% / 2 - var(--dialog-content-gap-x));
    margin: 0;
    display: flex;
    justify-content: center;
  }
}
</style>
