<template>
  <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
  <div v-else>
    <div v-if="noEntries" class="p-d-flex p-jc-center p-mt-5">Keine vergangenen Abwesenheiten</div>
    <div v-else class="pastAbsenceBox">
      <div class="pastAbsencesPanel">
        <span>Vergangene Abwesenheiten anzeigen</span>
        <Button id="togglePastAbsences" :icon="getButtonIcon()" @click="togglePastAbsences" />
      </div>
      <div>
        <TableRow v-if="showPastAbsences" :values="pastAbsences" :confirm-type="'past'" @holiday-deleted="emit('holidayDeleted')" />
      </div>
    </div>
    <TableRow :values="futureAbsences" :confirm-type="'future'" @holiday-deleted="emit('holidayDeleted')" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import ProgressSpinner from "primevue/progressspinner";
import { HolidayRequest } from "@/data-types";
import erpnextApi from "@/rest/ErpnextApi.ts";
import TableRow from "@/ui/TableRow.vue";
import Button from "primevue/button";
import { getAbsencesBeforeToday, getAbsencesOfTodayAndAfter, sortAbsencesByDateAsc, sortAbsencesByDateDesc } from "@/features/absence/utils/Absence.ts";

interface Props {
  newRequestsSubmitted: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  holidayDeleted: [];
}>();

const isLoading = ref(true);
const absences = ref<HolidayRequest[]>([]);
const pastAbsences = ref<HolidayRequest[]>([]);
const futureAbsences = ref<HolidayRequest[]>([]);
const showPastAbsences = ref(false);

onMounted(() => {
  loadAbsences();
});
watch(props, () => {
  loadAbsences();
});

watch(
  () => props.newRequestsSubmitted,
  () => {
    loadAbsences();
  },
);

function togglePastAbsences() {
  showPastAbsences.value = !showPastAbsences.value;
}

function getButtonIcon() {
  return showPastAbsences.value ? "pi pi-chevron-up" : "pi pi-chevron-down";
}

async function loadAbsences() {
  isLoading.value = true;
  const [holidayResponses, illnessResponses] = await Promise.all([erpnextApi.getHolidayRequests(), erpnextApi.getIllnessRequests()]);

  const modifiedHolidayResponses: HolidayRequest[] = holidayResponses.map((holiday) => ({
    ...holiday,
    leave_type: `🌴 ${holiday.leave_type}`,
  }));

  const modifiedIllnessResponses: HolidayRequest[] = illnessResponses.map((illness) => ({
    ...illness,
    leave_type: `🛌 ${illness.leave_type}`,
  }));

  const holidayAndIllnessAbsences = [...modifiedHolidayResponses, ...modifiedIllnessResponses];
  absences.value = holidayAndIllnessAbsences;

  pastAbsences.value = sortAbsencesByDateDesc(getAbsencesBeforeToday(holidayAndIllnessAbsences));
  futureAbsences.value = sortAbsencesByDateAsc(getAbsencesOfTodayAndAfter(holidayAndIllnessAbsences));

  isLoading.value = false;
}

const noEntries = computed(() => absences.value.length === 0);
</script>

<style scoped>
.pastAbsenceBox {
  border: 1px solid var(--borderColor);
}

.pastAbsencesPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  padding: 0.5rem;
}
</style>
